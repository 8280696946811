<template>
    <div class="wildfire-widget">
        <input v-on:input="onSmokeUpdate" type="checkbox" id="smoke" name="smoke density">
        <label for="smoke"> Smoke Density</label><br>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    name: 'wildfiresWidgetComponent',
    data(){
        return{
            smoke: false,
        }
    },
    methods:{
        onSmokeUpdate(){
            this.smoke = ($('#smoke').is(':checked'));
            this.$emit('sendValue', {type:'fv-checkbox', name:'smoke-density?', value: this.smoke});
        }
    }
}
</script>

<style scoped>
    .wildfire-widget{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
</style>